import { useEffect, useRef } from "react";

/**
 * Runs the callback on every change of dependencies, but NOT on the first render.
 */
export const useChange = (func: () => (void | (() => void)), deps: unknown[]) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, [...deps]);
}